import React from "react";
import logo from "./../assets/img/reg.svg"
import grilogo from "./../assets/img/gri.svg"
import {Badge, Col, Container, Nav, NavItem, NavLink, Row} from "reactstrap";
import {FiFacebook, FiLinkedin, FiTwitter} from "react-icons/all";
import {getSiteConfig} from "../App";

class Footer extends React.Component {
    render() {
        let fmt1 = getSiteConfig("footer_menu_1_title")
        let fmt2 = getSiteConfig("footer_menu_2_title")
        let fmt3 = getSiteConfig("footer_menu_3_title")

        let fm_list_1 = getSiteConfig("footer_menu_1")
        let fm_list_2 = getSiteConfig("footer_menu_2")
        let fm_list_3 = getSiteConfig("footer_menu_3")

        let all_menus = [
            {
                title: fmt1,
                list: fm_list_1
            },
            {
                title: fmt2,
                list: fm_list_2
            },
            {
                title: fmt3,
                list: fm_list_3
            }
        ]

        let footer_text_title = getSiteConfig("footer_widget_1"),
            footer_text_content = getSiteConfig("footer_widget_text"),
            footer_facebook = getSiteConfig("facebook"),
            footer_twitter = getSiteConfig("twitter"),
            footer_linkedin = getSiteConfig("linkedin")

        return <div className={"footer bg-dark text-white pt-5"}>
            <Container>
                <Row>
                    <Col className={"footer-widget"}>
                        {footer_text_title ? <h5>{footer_text_title}</h5> : ""}
                        {footer_text_content ? <p>{footer_text_content}</p> : ""}
                        {footer_facebook ? <a href={footer_facebook} target={"_blank"} rel={"noreferrer"}
                                              className={"btn btn-outline-light m-1"}><FiFacebook/></a> : ""}
                        {footer_twitter ? <a href={footer_twitter} target={"_blank"} rel={"noreferrer"}
                                             className={"btn btn-outline-light m-1"}><FiTwitter/></a> : ""}
                        {footer_linkedin ? <a href={footer_linkedin} target={"_blank"} rel={"noreferrer"}
                                              className={"btn btn-outline-light m-1"}><FiLinkedin/></a> : ""}

                        <div className={"w-100 d-block pt-3"}>
                            <Badge className={"bg-primary"}>&copy; {new Date().getFullYear()}</Badge>
                        </div>
                    </Col>
                    <Col>
                        <Row>
                            {all_menus.map(menu => {
                                return <Col className={"footer-widget"}>
                                    {menu.title ? <h5>{menu.title}</h5> : ""}
                                    {menu.list ?
                                        <Nav className={"footer-nav"}>{(menu.list).map(item => <NavItem><NavLink
                                            href={item.url}>{item.label}</NavLink></NavItem>)}</Nav> : ""}
                                </Col>
                            })}
                        </Row>
                    </Col>
                </Row>
            </Container>
            <div className={"footer-last d-flex bg-light text-dark p-2 mt-4"}>
                <div className="text-center w-100 pt-2">
                    <p>
                        <img src={logo} height={20} alt={"reg-logo"}/> bir <a href={"https://www.gri.net"}
                                                                              rel={"noreferrer"} target={"_blank"}><img
                        src={grilogo} height={20} alt={"gri-logo"}/></a> iştirakidir.
                    </p>
                </div>
            </div>
        </div>
    }
}

export default Footer