import React from "react";
import {Button} from "reactstrap";

class ButtonLoading extends React.Component {
    render() {
        let {text} = this.props
        return (
            <>
             <Button {...this.props} disabled={this.props.loading}>
                 {this.props.loading ? <div className={"mt-1 spinner-border"} role={"status"} /> : (text||"Gönder")}
             </Button>
            </>
        );
    }
}

export default ButtonLoading