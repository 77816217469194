export const config = {
    "language": "en",
    "moment_locale": "en-gb",
    "slug": "whoisrocket",
    "home-title": "whoisrocket.com",

    "home-description": "Find your great domain fastly!",
    "top-menu": [
        {
            url: "https://www.gri.net/domain-kayit?language=english",
            label: "Register a New Domain"
        }, {
            url: "https://www.gri.net/domain-transfer?language=english",
            label: "Domain Transfer"
        }, {
            url: "https://www.gri.net/domain-fiyatlari?language=english",
            label: "Pricing"
        }
    ],
    "faq": [
        {
            key: "0",
            question: "Sorguladığım domainler müsait değil, daha önceden kaydedilmiş. Ne yapabilirim?",
            answer: <>
                <p>Sorguladıktan sonra dolu olarak görüntülediğiniz domainler için, domain sahibine ulaşarak domaini satın almayı deneyebilir veya domainin süresi bitmesini bekleyip boşa düşmesi halinde satın alabilirsiniz.</p>
            </>
        }
    ],
    "supported_tlds_text": "SUPPORTED TLDS",
    "search_button_text": "Search",
    "invalid_domain_error": "Please send valid domain name.",
    "domain_invalid_tooltip": "Domain is you entered invalid! ",
    "owner_not_found": "Owner details could not found!",
    "tld_label": "TLDs",
    "footer_widget_1": "About of gri",
    "footer_widget_text": "Gri is your solution partner that offers you web services (especially domain).",
    "facebook": "https://www.facebook.com/gridotnet",
    "twitter": "https://twitter.com/gridotnet",
    "linkedin": "https://linkedin.com/company/gri",
    "footer_menu_1_title": ".tr Domain",
    "footer_menu_1": [
        {
            url: "https://www.reg.com.tr/domain-sorgulama",
            label: "Register .tr Domain"
        },
        {
            url: "https://www.reg.com.tr/toplu-domain-sorgulama",
            label: "Bulk Lookup"
        },
        {
            url: "https://www.reg.com.tr/domain-transfer",
            label: "Transfer .tr Domain"
        },
        {
            url: "https://www.reg.com.tr/domain-fiyatlari",
            label: ".tr Domain Pricing"
        }
    ],
    "footer_menu_2_title": "Support",
    "footer_menu_2": [
        {
            url: "https://www.gri.net/iletisim-formu?language=english",
            label: "Contact Us",
        },
        {
            url: "https://www.gri.net/submitticket?language=english",
            label: "Create a Ticket"
        }
    ],
    "footer_menu_3_title": "Gri",
    "footer_menu_3": [
        {
            url: "https://www.gri.net/hakkimizda?language=english",
            label: "About"
        },
        {
            url: "https://www.gri.net/kurumsal-bilgiler?language=english",
            label: "Corporate Information"
        },
        {
            url: "https://www.gri.net/kullanim-sozlesmesi?language=english",
            label: "User Agreement"
        },
        {
            url: "https://www.gri.net/gizlilik-politikamiz?language=english",
            label: "Confidentiality Agreement"
        },
        {
            url: "https://www.gri.net/iade-sartlari?language=english",
            label: "Refund Policy"
        }
    ],
    "domain_is_not_available": "is not available!",
    "update_now": "Update Now!",
    "domain_details_title": "Domain Information",
    "registration_date": "Registration Date:",
    "expiry_date": "Expiry Date:",
    "domain_age": "Age:",
    "status_code": "Status Code:",
    "name_servers": "Nameservers",
    "registrar": "Registrar",
    "show_owner_details": "Show Owner Details",
    "show_whois_raw": "Full Whois Result",
    "owner_details_title": "Owner Details",
    "owner_name": "Name:",
    "owner_organization": "Orgnization:",
    "owner_address": "Address:",
    "owner_phone": "Phone number:",
    "close_label": "Close",
    "refresh_limit_error": "You can update whois data every 30 minutes!",
    "last_update": "Last Updated",
    "lock_status": "Transfer lock:",
    "active": "ACTIVE",
    "search_new_domain": "Check New Domain",
    "domain_available": "available!",
    "register_button": "Register Now",
    "tax_excluded": "*Tax excluded",
    "idn_label": "IDN Domain",
    "domain_use_q1": "How can I use the domain by registering it?",
    "domain_use_a1": "You can create a website with the domain (internet domain name) you will register and use it as an extension of your e-mail address.",
    "domain_use_q2": "Can I sell this domain?",
    "domain_use_a2": "You can sell the domain to a different person/organization by registering the extensions that have no restrictions on sale and transfer. You can use the domain as an investment tool.",
    "domain_use_help_url": "https://www.gri.net/iletisim-formu.php?language=english",
    "other_questions_support": "Do you have another questions?",
    "contact_us": "Contact Us",
    "faq_title": "F.A.Q."
}