import React from "react";
import logo from "./../assets/img/reg.svg"
import logoDomainWhois from "./../assets/img/domain-whois.png"
import logoWhoisRocket from "./../assets/img/whois-rocket-logo.png"
import {Nav, Navbar, NavbarToggler, NavItem, NavLink, Collapse} from "reactstrap";
import classnames from "classnames";
import {IoClose} from "react-icons/all";
import {get_site_slug, getSiteConfig} from "../App";


const logoSwitch = () => {
    switch (get_site_slug())
    {
        case "domainwhois":
            return <img src={logoDomainWhois} height={100} alt={"Logo"} />;
            break;
        case "whoisrocket":
            return <img src={logoWhoisRocket} height={50} alt={"Logo"} />;
            break;
        default:
            return <img src={logo} height={50} alt={"logo"}/>;
            break;
    }
}
class Header extends React.Component {
    state = {collapsed: !false}

    _nav = null

    componentDidMount() {
        document.addEventListener('click', (e) => {
            const container = this._nav;
            if (typeof container !== "undefined" && container !== null) {
                if (e.target !== container && !container.contains(e.target) && !this.state.collapsed) {
                    this.toggle();
                }
            }
        })
    }

    toggle = () => {
        let {collapsed} = this.state
        this.setState({collapsed: !collapsed})
    }

    render() {

        const menu = getSiteConfig("top-menu");

        let {collapsed} = this.state

        return <div className={classnames("header d-flex bg-light p-3", {
            "menu-open": !collapsed
        })}>
            <div className="logo">
                <span className={"cursor-pointer"} onClick={() => {
                    window.postMessage({command: "whois_reset"}, "*");
                }}>{logoSwitch()}</span>
            </div>
            <div className={"d-block d-lg-none mobile-navbar"} ref={(c) => this._nav = c}>
                <Navbar color="faded" light>
                    <NavbarToggler className="mr-2" onClick={this.toggle}/>
                    <Collapse isOpen={!collapsed} navbar>
                        <Nav navbar>
                            {menu.map(m => {
                                return <NavItem>
                                    <NavLink href={m.url}>{m.label}</NavLink>
                                </NavItem>
                            })}
                        </Nav>
                        <p onClick={() => {
                            this.toggle()
                        }} className={"text-center mt-2"}><span className={"text-center bg-danger text-white btn btn-danger rounded-circle"}
                              style={{width: 48, height: 48, textAlign: "center", padding: 0, lineHeight: "42px"}}
                        ><IoClose size={25}/></span></p>
                    </Collapse>
                </Navbar>
            </div>
            <Nav className={"d-none d-lg-flex w-100 text-right main-menu"}>
                {menu.map(m => {
                    if (typeof m.mobileOnly === "boolean" && m.mobileOnly)
                        return "";
                    return <NavItem>
                        <NavLink href={m.url}>{m.label}</NavLink>
                    </NavItem>
                })}
            </Nav>
            {get_site_slug() === "default" ? <a rel={"noreferrer"} href={"https://www.reg.com.tr/index.php?rp=/login"}
                                                style={{color: "#fff", lineHeight: "38px"}}
                                                target={"_blank"} className={"d-none d-lg-block btn btn-secondary text-nowrap"}>Müşteri Paneli</a> : ""}
        </div>
    }
}
export default Header