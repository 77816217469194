import {Badge, Button, Nav, NavItem, NavLink} from "reactstrap";
import {AiOutlinePauseCircle} from "react-icons/all";
import {Animated} from "react-animated-css";
import React from "react";
import {getSiteConfig} from "../App";

class WhoisTlds extends React.Component {
    state = {
        suggestions: [
            ".com.tr",
            ".net.tr",
            ".com",
            ".net"
        ]
    }


    render() {
        let self = this;
        let {suggestions} = this.state
        const total_tld = 569;
        return <>
            <div className={"d-none d-lg-flex bg-gradient pt-1"} onMouseEnter={() => {
                self.setState({pause: true})
            }} onMouseLeave={() => {
                self.setState({pause: false})
            }}>
                {this.state.idnDomain !== "" ? this.state.idnDomain : ""}
                <Badge className={"bg-warning position-relative"}
                       style={{
                           lineHeight: "32px",
                           fontSize: 16,
                           paddingLeft: 40,
                           paddingRight: 40
                       }}>{getSiteConfig("supported_tlds_text").toLocaleUpperCase()}</Badge>
                <Nav className={"whois-suggestion-list overflow-hidden w-100"}>
                    {
                        suggestions.map((item, index) => <div>
                            <NavItem><NavLink
                                onClick={() => {
                                    if (typeof this.props.useTld === "function")
                                        this.props.useTld(item)
                                }}
                                href={"#"}
                                className={"text-white"}>{item}</NavLink></NavItem>
                        </div>)
                    }
                    <div className="">
                        <a target={"_blank"} className={"more-tld-link ml-2 d-inline-block"}
                           style={{lineHeight: "40px"}}
                           href={"https://www.gri.net/domain-fiyatlari.php"}>+{total_tld - suggestions.length} {getSiteConfig("tld_label")}</a>
                    </div>
                </Nav>
            </div>
        </>
    }
}

export default WhoisTlds