import React from "react";
import {Button, Col, Row, UncontrolledTooltip} from "reactstrap";
import {BiChevronRight, FaLongArrowAltRight, FiInfo, HiBadgeCheck, IoReload, VscQuestion} from "react-icons/all";
import {Animated} from "react-animated-css";
import punycode from "punycode";
import {getCLS} from "web-vitals";
import {getSiteConfig} from "../App";

export class QueryAgainButton extends React.Component {
    render() {
        return <>
            <p className={"d-none d-lg-block mt-3"}>&nbsp;</p>
            <p className={"d-none d-lg-block mt-3"}>&nbsp;</p>
            <p className={"mt-3 text-center"}>
                <Button
                    onClick={() => {
                        window.postMessage({
                            command: "refresh_query"
                        }, "*");
                    }}
                    size={"sm"} color={"dark"}>{getSiteConfig("search_new_domain")} <IoReload size={21}/></Button>
            </p>
            <p className={"d-block d-lg-none mt-3"}>&nbsp;</p>
        </>;
    }
}

class WhoisResultAvailable extends React.Component {
    render() {
        let {domain, urlForPurchase, priceForPurchase} = this.props
        let idnDomain = domain === punycode.toASCII(domain) ? false : punycode.toASCII(domain);
        return <Row>
            <Col className={"text-center"}>
                <h2 className={"text-white "}><HiBadgeCheck/> <strong
                    style={{textDecoration: "underline"}}>{domain}</strong> {getSiteConfig("domain_available")}</h2>
                {idnDomain ? <>
                    <UncontrolledTooltip target={"idn-domain-info"}>
                        <strong>{getSiteConfig("idn_label")}</strong>
                    </UncontrolledTooltip>
                    <span className={"text-dark d-block"}><FiInfo id={"idn-domain-info"}/> {idnDomain}</span></> : ""}
                {urlForPurchase &&
                <a rel={"noreferrer"}
                   href={urlForPurchase || "#"}
                   target={"_blank"}
                   className={"btn btn-primary mt-3 text-white"}
                >{getSiteConfig("register_button")} {priceForPurchase ? `₺${priceForPurchase}*` : ""}
                    <FaLongArrowAltRight style={{marginTop: -5, marginLeft: 10, marginRight: 5}}/>
                </a>}
                {priceForPurchase && <p className={"text-white opacity-75"}
                                        style={{fontStyle: "italic", fontSize: 12}}>{getSiteConfig("tax_excluded")}</p>}
                <QueryAgainButton/>
            </Col>
            <Col>
                <Animated animationIn={"fadeInUp"}>
                    <div className={"bg-white rounded p-3 shadow mb-3"}>
                        <strong>{getSiteConfig("domain_use_q1")}</strong>
                        <p>{getSiteConfig("domain_use_a1")}</p>
                    </div>
                </Animated>
                <Animated animationIn={"fadeInUp"}>
                    <div className={"bg-white rounded p-3 shadow"}>
                        <strong>{getSiteConfig("domain_use_q2")}</strong>
                        <p>{getSiteConfig("domain_use_a2")}</p>
                    </div>
                </Animated>
                <Animated animationIn={"fadeInUp"}>
                    <div className={"d-flex p-3"}>
                        <h5 className={"w-100 text-white mt-3"} style={{lineHeight: "30px"}}>
                            <VscQuestion/> {getSiteConfig("other_questions_support")} <Button color={"dark text-nowrap"}
                                                                                              onClick={() => {
                                                                                                  window.open(getSiteConfig("domain_use_help_url"), '_blank');
                                                                                                  return false;
                                                                                              }}>{getSiteConfig("contact_us")}
                            <BiChevronRight style={{marginTop: -3}}/></Button></h5>
                    </div>
                </Animated>
            </Col>
        </Row>
    }
}

export default WhoisResultAvailable