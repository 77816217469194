export const config = {
    "language": "tr",
    "slug": "reg",
    "moment_locale": "tr-tr",
    "home-title": "Reg - Whois Sorgulama",
    "home-description": "Domain sahip bilgilerini ve müsaitlik durumunu sorgulayın.",
    "search_button_text": "Ara",
    "invalid_domain_error": "Lütfen geçerli bir domain giriniz.",
    "domain_invalid_tooltip": "Domain uygun görünmüyor!",
    "owner_not_found": "Sahip bilgisi bulunamadı!",
    "tld_label": "Uzantı",
    "top-menu": [
        {
            url: "https://www.reg.com.tr/domain-sorgulama",
            label: "Domain Kayıt"
        }, {
            url: "https://www.reg.com.tr/marka/online-marka-tescili",
            label: "Marka Tescili"
        }, {
            url: "https://www.reg.com.tr/domain-transfer",
            label: "Domain Transfer"
        }, {
            url: "https://www.reg.com.tr/domain-fiyatlari",
            label: "Domain Fiyatları"
        }, {
            url: "https://www.reg.com.tr/clientarea.php",
            label: "Müşteri Paneli",
            mobileOnly: true
        }
    ],
    "faq": [
        {
            key: "0",
            question: "Sorguladığım domainler müsait değil, daha önceden kaydedilmiş. Ne yapabilirim?",
            answer: <>
                <p>Sorguladıktan sonra dolu olarak görüntülediğiniz domainler için, domain sahibine ulaşarak domaini
                    satın almayı deneyebilir veya domainin süresi bitmesini bekleyip boşa düşmesi halinde satın
                    alabilirsiniz.</p>
            </>
        },
        {
            key: "1",
            question: "Bir web sitesi sahibi olmak istiyorum. Domaini kaydettikten sonra ne yapmam gerekiyor?",
            answer: <>
                <p>Domainler web sitelerinin isimleridir. Domain kaydettiğinizde web sitenizin sadece ismini
                    belirlemiş olursunuz.</p>
                <p>Web sitenizi yayına almak için domain kayıt işleminden sonra bir web hosting paketi satın almalı
                    ve akabinde sitenizin tasarım &amp; yazılım işlemlerini yapmalı veya bir uzmandan destek
                    almalısınız.</p>
                <p>Konuyla ilgili desteğe ihtiyacınız varsa <a href={"tel:+908508404060"}>0850 840 40 60</a> nolu
                    telefondan veya <a rel={"noreferrer"} href={"https://www.gri.net/submitticket.php"}
                                       target={"_blank"}>burdan</a> destek talebi
                    açarak uzmanlarımızla görüşebilirsiniz.</p>
            </>
        },
        {
            key: "2",
            question: "Belge gerekmeden hangi .tr uzantılarıyla domain kaydedebilirim?",
            answer: <>
                <p><strong>com.tr</strong>, <strong>net.tr</strong>, <strong>org.tr</strong>, gen.tr, web.tr, name.tr,
                    info.tr, biz.tr, bbs.tr uzantılı domainleri, herhangi bir belge
                    gerekmeden kaydedebilirsiniz.</p>
            </>
        }
    ],
    "supported_tlds_text": "DESTEKLENEN UZANTILAR",
    "footer_widget_1": "reg ® Hakkında",
    "footer_widget_text": "reg.com.tr, kullanıcılarına .tr uzantılı domain ihtiyaçları başta olmak üzere, diğer web hizmetlerini en uygun fiyatlara sunan bir web girişimidir.",
    "facebook": "https://www.facebook.com/gridotnet",
    "twitter": "https://twitter.com/gridotnet",
    "linkedin": "https://linkedin.com/company/gri",
    "footer_menu_1_title": ".tr Domain",
    "footer_menu_1": [
        {
            url: "https://www.reg.com.tr/domain-sorgulama",
            label: "Domain Sorgulama"
        },
        {
            url: "https://www.reg.com.tr/toplu-domain-sorgulama",
            label: "Toplu Domain Sorgulama"
        },
        {
            url: "https://www.reg.com.tr/domain-transfer",
            label: "Domain Transfer"
        },
        {
            url: "https://www.reg.com.tr/domain-fiyatlari",
            label: "Domain Fiyatları"
        }
    ],
    "footer_menu_2_title": "Destek",
    "footer_menu_2": [
        {
            url: "https://www.reg.com.tr/iletisim",
            label: "İletişim",
        },
        {
            url: "https://www.reg.com.tr/submitticket.php",
            label: "Destek Talebi Oluştur"
        },
        {}
    ],
    "footer_menu_3_title": "reg®",
    "footer_menu_3": [
        {
            url: "https://www.reg.com.tr/hakkimizda",
            label: "reg® hakkında"
        },
        {
            url: "https://www.reg.com.tr/kurumsal-bilgiler",
            label: "Kurumsal Bilgiler"
        },
        {
            url: "https://www.reg.com.tr/kullanim-sozlesmesi",
            label: "Kullanım Sözleşmesi"
        },
        {
            url: "https://www.reg.com.tr/gizlilik-sozlesmesi",
            label: "Gizlilik Sözleşmesi"
        },
        {
            url: "https://www.reg.com.tr/iade-sartlari",
            label: "İade Şartları"
        }
    ],
    "domain_is_not_available": "müsait değil!",
    "update_now": "Şimdi Güncelle!",
    "domain_details_title": "Domain Bilgileri",
    "registration_date": "Kayıt Tarihi:",
    "expiry_date": "Bitiş Tarihi:",
    "domain_age": "Yaşı:",
    "status_code": "Durum Kodu:",
    "name_servers": "İsim Sunucuları",
    "registrar": "Kayıt Şirketi",
    "show_owner_details": "Sahip Bilgilerini Göster",
    "show_whois_raw": "Tam WHOIS Çıktısı",
    "owner_details_title": "Sahip Bilgileri",
    "owner_name": "İsim:",
    "owner_organization": "Firma:",
    "owner_address": "Adres:",
    "owner_phone": "Telefon:",
    "close_label": "Kapat",
    "refresh_limit_error": "30 dakikada 1 yenileme yapabilirsiniz.",
    "last_update": "Son Güncelleme",
    "lock_status": "Transfer kilidi:",
    "active": "AKTİF",
    "search_new_domain": "Yeni Bir Domain Sorgula",
    "domain_available": "müsait!",
    "register_button": "Şimdi Kaydet",
    "tax_excluded": "*Vergiler hariç",
    "idn_label": "IDN Domain",
    "domain_use_q1": "Domaini kaydederek ne şekilde kullanabilirim?",
    "domain_use_a1": "Kaydedeceğiniz domain (internet alan adı) ile bir web sitesi oluşturabilir, e-posta adresinizin uzantısı olarak kullanabilirsiniz.",
    "domain_use_q2": "Bu domaini satabilir miyim?",
    "domain_use_a2": "Satış ve devrinde kısıt bulunmayan uzantılarda domaini kaydederek farklı bir kişi/kuruma satabilirsiniz. Domaini bir yatırım aracı olarak kullanabilirsiniz.",
    "domain_use_help_url": "https://www.reg.com.tr/iletisim.php",
    "contact_us": "Bİze Ulaşın",
    "other_questions_support": "Diğer sorularınız için",
    "faq_title": "Sık Sorulan Sorular"

}