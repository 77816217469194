class WhoisReqs {
    base_url = ""

    constructor() {
        this.base_url = process.env.REACT_APP_WHOIS_BASE_URL;
    }

    request = (url, method, data) => {
        url = this.base_url + url;
        let fetch_opts = {}
        fetch_opts['method'] = method.toLowerCase();
        if (method.toLowerCase() === 'post') {
            if (typeof data === "object")
                data = JSON.stringify(data)
            method['body'] = data;
        }

        if (method.toLowerCase() === 'get' && typeof data === "object" && Object.keys(data).length > 0) {
            url += '?';
            Object.keys(data).forEach((item, i) => {
                let d = data[item];
                if (i)
                    url += '&';
                url += item + '=' + d;
            })
        }

        return fetch(url, fetch_opts).then(r => r.json())
    }

    whois = (domain, force, referrer) => {
        let self = this;
        if(!referrer)
            referrer = "no"
        else
            referrer = "yes"
        return new Promise(function (resolve, reject) {
            self.request(
                'api/Whois',
                'get',
                {
                    domain,
                    force: force ? "yes" : "no",
                    referrer
                }
            ).then(r => {
                if (
                    (typeof r.status !== "boolean" || !r.status) &&
                    (typeof r.status !== "number" || r.status !== 1)
                )
                    reject(r.message || "Bir hata oluştu!")
                resolve(r.data);
            })
        })
    }

}

export const Whois = new WhoisReqs()