import {Badge, Nav, NavItem, NavLink} from "reactstrap";
import {AiOutlinePauseCircle} from "react-icons/all";
import {Animated} from "react-animated-css";
import React from "react";

class WhoisSuggestion extends React.Component {
    state = {
        suggestions: [
            ".tr uzantılı domainler",
            "global domainler",
            "jenerik domainler",
            "en iyi domainler",
            "yeni uzantılar",
            "popüler uzantılar"
        ],
        showFirst: 0,
        showLast: 2
    }

    componentDidMount() {
        let self = this;

        let max = self.state.suggestions.length

        this.timer = setInterval(function () {
            if (!self.state.pause) {
                let new_first = (self.state.showFirst + 3) % max
                let new_last = (self.state.showLast + 3) % max
                self.setState({
                    showLast: new_last,
                    showFirst: new_first
                })
            }
        }, 7.5 * 1000)
    }

    render() {
        let self = this;
        let {suggestions} = this.state
        return <>
            <div className={"d-none d-lg-flex bg-gradient pt-1"} onMouseEnter={() => {
                self.setState({pause: true})
            }} onMouseLeave={() => {
                self.setState({pause: false})
            }}>
                {this.state.idnDomain !== "" ? this.state.idnDomain : ""}
                <Badge className={"bg-warning position-relative"}
                       style={{
                           lineHeight: "32px",
                           fontSize: 16,
                           paddingLeft: 40,
                           paddingRight: 40
                       }}>{"Öneriler".toLocaleUpperCase()}{this.state.pause &&
                <AiOutlinePauseCircle style={{position: "absolute", right: 5, top: 13}}/>}</Badge>
                <Nav className={"whois-suggestion-list overflow-hidden"}>
                    {
                        suggestions.map((item, index) => <Animated animationIn="bounceInDown"
                                                                   animationOut="bounceOutUp"
                                                                   className={index >= this.state.showFirst && index <= this.state.showLast ? "" : "d-none"}
                                                                   isVisible={index >= this.state.showFirst && index <= this.state.showLast}>
                            <div>
                                <NavItem><NavLink href={"#"}
                                                  className={"text-white"}>{item}</NavLink></NavItem>
                            </div>
                        </Animated>)
                    }
                </Nav>
            </div>
        </>
    }
}

export default WhoisSuggestion